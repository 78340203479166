import { scrollTo } from "@utils/scrollTo";
import { useEffect, useState } from "react";

export default function Footer({ env }) {
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    const position = window.scrollY;
    if (position >= 300) {
      setShowButton(true);
    }
    if (position < 300) {
      setShowButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="footer-section section">
        <div className="footer-top">
          <img
            className="shape-1 movebounce-01"
            src="/images/shape/shape-16.webp"
            width="185"
            height="231"
            alt="Shape"
          />
          <img
            className="shape-3 movebounce-01"
            src="/images/shape/shape-18.webp"
            width="503"
            height="287"
            alt="Shape"
          />

          <div className="container">
            <div className="footer-widget-wrapper">
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div
                    className="footer-widget"
                    // data-aos="fade-up"
                    // data-aos-delay="150"
                  >
                    <h4 className="widget-title">Kontakt</h4>

                    <div className="footer-widget-info">
                      <div className="single-widget-info">
                        <h6 className="title">Kosmetik Salon – Julia Eckert</h6>
                        <p>
                          Goldene Hufe 24
                          <br />
                          04329 Leipzig
                        </p>
                      </div>
                      <div className="single-widget-info">
                        <h6 className="title">Web</h6>
                        <p>
                          <a href="mailto:julia@eckert.live">
                            julia@eckert.live
                          </a>
                        </p>
                        <p>
                          <a href="https://julia.eckert.live">
                            julia.eckert.live
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="footer-widget">
                    <h4 className="widget-title">Anfahrt</h4>

                    <div className="footer-widget-link">
                      <ul>
                        <li>
                          <a
                            href="https://goo.gl/maps/xUBcw53rEjMieYxdA"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={
                                "/images/kosmetik-salon/map-small-v2024-06.png?v=" +
                                env.ASSET_VERSION
                              }
                              className="img-fluid"
                              alt={env.SEO_TITLE}
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="footer-widget">
                    <h4 className="widget-title">INFORMATIONEN</h4>

                    <div className="footer-widget-link">
                      <ul>
                        <li>
                          <a href={"/angebote"}>Unsere Angebote</a>
                        </li>
                        <li>
                          <a href={"/gutschein-service"}>Gutschein-Service</a>
                        </li>
                        <li>
                          <a href={"/unsere-produkte"}>Unsere Produkte</a>
                        </li>
                        <li>
                          <a href={"/kontakt"}>Kontakt</a>
                        </li>
                        <li>
                          <a href={"/impressum"}>Impressum</a>
                        </li>
                        <li>
                          <a href={"/datenschutzerklaerung"}>
                            Datenschutzerklärung
                          </a>
                        </li>
                        <li></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="footer-widget">
                    <h4 className="widget-title">Öffnungszeiten</h4>

                    <div className="footer-widget-info">
                      <div className="single-widget-info">
                        <h6 className="title">Montag bis Freitag</h6>
                        <p>8:00 - 20:00 Uhr</p>
                      </div>
                      <div className="single-widget-info">
                        <h6 className="title">Samstag</h6>
                        <p>nach Vereinbarung</p>
                      </div>
                    </div>
                    <div className="single-widget-info">
                      <h5 className="title">Wunschtermin vereinbaren</h5>
                      <p>
                        <a
                          href="https://wa.me/4917650515451"
                          className="no-word-break"
                          title="WhatsApp"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src="/images/svg/whatsapp.svg"
                            className="contact-icon"
                            alt="WhatsApp"
                          />
                          (0176) 50 51 54 51
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 text-center">
                  <hr />
                  <small style={{ fontSize: "11px", lineHeight: "9px" }}>
                    Es wird ausdrücklich darauf hingewiesen, dass die
                    angegebenen Preis seit der letzten Aktualisierung sich
                    geändert haben könnten, da eine Echtzeit-Aktualisierung der
                    angegebenen Preise technisch nicht immer möglich ist.
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-copyright">
          <div className="container">
            <div className="copyright-wrapper flex-row-reverse">
              <div className="copyright-text">
                <p>
                  &copy; {new Date().getFullYear()}{" "}
                  <i className="fa fa-heart"></i>{" "}
                  <a href={env.WEBSITE_URL}>Kosmetik Salon – Julia Eckert</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showButton && (
        <button className="back-to-top" onClick={scrollTo}>
          <i className="icofont-simple-up"></i>
        </button>
      )}
    </>
  );
}
